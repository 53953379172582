<style lang="less" scoped>
  .iconContents {
    height: 200px;
    overflow-y: scroll;
    .iconBtn {
      display: inline-block;
      font-size: 20px;
      padding: 5px;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 25px;
      cursor: pointer;
    }
    .active_color {
      color: #fff;
      background-color: #1890ff;
    }
  }
</style>
<template>
  <div class="iconContents">
    <div
      v-for="(i, key, index) in items"
      :key="index"
      :class="select.selected == key ? 'iconBtn active_color' : 'iconBtn'"
      @click="onChange(key)"
    >
      <my-icon :icon="key"></my-icon>
    </div>
  </div>
</template>
<script>
  import mixins from './SelectMixin.js'
  import * as IconMap from '@icon-park/vue-next/es/map'
  import MyIcon from '@/common/components/MyIcon/index.vue'
  export default {
    mixins: [mixins],
    components: { MyIcon },
    props: {
      value: {
        required: false,
        type: String,
        default: undefined,
      },
    },
    data() {
      return {
        select: {
          selected: undefined,
        },
      }
    },
    watch: {
      value(e) {
        this.select.selected = e
      },
    },
    methods: {
      onChange(val) {
        this.select.selected = val
        this.$emit('update:value', val)
        console.log(val, 'oipop')
      },
      onInit() {
        let _this = this
        setTimeout(() => {
          _this.items = IconMap
          console.log(1111)
        }, 500)
      },
    },
  }
</script>
