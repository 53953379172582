<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    v-bind="rformProps.modelRef"
    @submit="onSubmit"
  >
    <a-form-item
      label="选择上级："
      name="parent_id"
      v-bind="validateInfos['parent_id']"
    >
      <my-menu-select v-model:value="model.parent_id"></my-menu-select>
    </a-form-item>
    <a-form-item label="菜单名称：" name="name" v-bind="validateInfos['name']">
      <a-input type="text" placeholder="名称" v-model:value="model.name" />
    </a-form-item>
    <a-form-item label="类型：" name="type" v-bind="validateInfos['type']">
      <my-menu-type-radio v-model:value="model.type"></my-menu-type-radio>
    </a-form-item>
    <a-form-item label="图标设置" name="icon" v-bind="validateInfos['icon']">
      <my-icon-select v-model:value="model.icon"></my-icon-select>
    </a-form-item>
    <a-form-item label="权限标识：">
      <a-input
        type="text"
        placeholder="标识"
        v-model:value="model.permission"
      />
    </a-form-item>
    <a-form-item label="页面路径：">
      <a-input type="text" placeholder="页面路径" v-model:value="model.url" />
    </a-form-item>

    <a-form-item :wrapper-col="formLayout.submitWrapperCol">
      <a-button type="primary" html-type="submit" @click="onSubmit">
        保存
      </a-button>
      <a-button style="margin-left: 10px" @click="$emit('closeModel')">
        关闭
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  import { reactive } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import MyMenuTypeRadio from '@/components/Radio/MyMenuType.vue'
  import MyMenuSelect from '@/common/components/Select/MyMenuSelect.vue'
  import MyIconSelect from '@/common/components/Select/MyIconSelect.vue'

  export default {
    inject: ['formLayout'],
    emits: ['finish'],
    components: {
      MyMenuTypeRadio,
      MyMenuSelect,
      MyIconSelect,
    },
    setup(props, context) {
      const modelRef = reactive({
        //表单
        name: undefined,
        type: undefined,
        url: undefined,
        icon: undefined,
        roles_id: undefined,
        permission: undefined,
        parent_id: undefined,
      })
      const rulesRef = reactive({
        //校验
        name: [
          {
            required: true,
            type: 'string',
            message: '请设置名称！',
            trigger: 'change',
          },
        ],
        type: [
          {
            required: true,
            type: 'string',
            message: '请选择类型！',
            trigger: 'change',
          },
        ],
        icon: [
          {
            required: true,
            type: 'string',
            message: '请设置图标！',
            trigger: 'change',
          },
        ],
        parent_id: [
          {
            required: true,
            type: 'number',
            message: '请设置上级！',
            trigger: 'change',
          },
        ],
      })

      const { validate, validateInfos } = useForm(modelRef, rulesRef)

      const onSubmit = (e) => {
        //提交
        e.preventDefault()
        validate()
          .then(() => {
            onSubmitForm()
          })
          .catch((err) => {
            console.log('error', err)
          })
      }

      const onSubmitForm = () => {
        requests({
          url: API.permissions.Store,
          data: modelRef,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '添加成功！',
            })
            context.emit('finish')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const rformProps = {
        modelRef,
        rulesRef,
      }

      return {
        rformProps,
        model: modelRef,
        rules: rulesRef,
        validateInfos,
        onSubmit,
      }
    },
  }
</script>
